<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.00013 6.98318C0.999958 6.99377 0.999958 7.00436 1.00013 7.01493V18C1.00013 19.6569 2.34327 21 4.00013 21H20.0001C21.657 21 23.0001 19.6569 23.0001 18V7.01495C23.0003 7.00436 23.0003 6.99377 23.0001 6.98317V6C23.0001 4.34315 21.657 3 20.0001 3H4.00013C2.34327 3 1.00013 4.34315 1.00013 6V6.98318ZM3.00013 6C3.00013 5.44772 3.44784 5 4.00013 5H20.0001C20.5524 5 21.0001 5.44772 21.0001 6V6.45063L12.4992 11.8526L12.497 11.854C12.3479 11.9468 12.1758 11.996 12.0001 11.996C11.8245 11.996 11.6524 11.9468 11.5033 11.854L3.00013 6.45063V6ZM21.0001 8.82027V18C21.0001 18.5523 20.5524 19 20.0001 19H4.00013C3.44784 19 3.00013 18.5523 3.00013 18V8.82028L10.4338 13.544L10.4392 13.5474C10.9071 13.8405 11.448 13.996 12.0001 13.996C12.5522 13.996 13.0932 13.8406 13.5611 13.5474L21.0001 8.82027Z"
    />
  </svg>
</template>
